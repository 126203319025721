import React, { useState, useEffect, useRef } from "react";
import { Tabbed } from "./components/Tabbed";
import Offers from "./components/offers/offers";
import Banner from "../src/components/images/logo.png";
import TextTruncate from "react-text-truncate";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "./components/icon/icon";
import { useNavigate } from "react-router-dom";
import TooltipComponent from "./components/tooltip/tooltip";
import { Label } from "@mui/icons-material";
import Box from "@mui/material/Box";
import News from "./components/news/news";
import axios from "axios";
import Papa from "papaparse";
import { handleData } from "./services/googleService";

import { insertDataIntoHomePage } from "./services/googleService";

const Home = () => {
  const boxRef = useRef();
  const navigate = useNavigate();
  const [showBox, setShowBox] = useState(true);
  const [data, setData] = useState(""); // State to hold the fetched data
  const [testData, setTestData] = useState([]);
  const boxesRef = useRef([]);

  const animationDelay = 200;
  useEffect(() => {
    const handleScroll = () => {
      boxesRef.current.forEach((box, index) => {
        const top = box.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        let isVisible = top < windowHeight;

        if (isVisible) {
          setTimeout(() => {
            box.classList.add("slide-in"); // Add slide-in animation class
          }, index * animationDelay);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleBoxClick = () => {
    navigate("/price");
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setShowBox(false);
      } else {
        setShowBox(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const offers = [
    {
      swedish: "5 Körlektioner för 2 600 kr (Inklusive vägmärkeboken)",
      english:
        "5 driving lessons for 2,600 SEK (Including the traffic signs book)",
    },
    {
      swedish: "10 Körlektioner för 5 200 kr (Inklusive vägmärkeboken)",
      english:
        "10 driving lessons for 5,200 SEK (Including the traffic signs book)",
    },
    {
      swedish:
        "20 Körlektioner för 10 400 kr (Inklusive vägmärkeboken + 3 månader teori online)",
      english:
        "20 driving lessons for 10,400 SEK (Including the traffic signs book + 3 months of online theory)",
    },
  ];

  return (
    <div className="homeWrapper">
      <div className="image-wrapper">
        <img src={Banner} className="image-banner" alt="autumndrive" />

        {showBox && (
          <Box
            ref={boxRef}
            className="responsive-box slide-in"
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              padding: 2,
              paddingRight: 5,
              maxHeight: 300,
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: 1,
              boxShadow: 5,
              width: "500px",
              backgroundColor: "rgba(0, 0, 0, 0.9)",
              color: "white",
              transition: "transform 0.5s ease", // Apply transition effect to transform property
              transform: "translateX(0)", // Initial position (not translated)
            }}
          >
            {/*{data ? (
              <>
                <h2 className="">
                  {data ? data[1]["InformationsBoxen"].value : ""}
                  Välkommen till vår trafikskola!
                </h2>
                <p
                  style={{
                    paddingTop: "10px",
                    margin: 0,
                    marginBottom: "10px",
                    fontSize: "0.9rem",
                  }}
                >
                  {data ? data[3]["InformationsBoxen"].value : ""}
                  På vår trafikskola prioriterar vi säkerhet. Våra erfarna instruktörer finns här för att vägleda dig genom din inlärningsresa och hjälpa dig att bli en självsäker och ansvarsfull förare.
                </p>
                {data && (
                  <p
                    style={{
                      paddingTop: "10px",
                      margin: 0,
                      fontSize: "0.9rem",
                    }}
                  >
                    {data[5]["InformationsBoxen"].value}
                    Vi erbjuder vi ett utbud av kurser som är anpassade efter dina behov. Kontakta oss idag för att komma igång!
                  </p>
                )}
                

                <div>
                  <TooltipComponent
                    title={
                      <h5 className="home__tooltip__content__box">Priser</h5>
                    }
                    placement="top-end"
                    wrappedContent={
                      <div
                        className="home__box__icon"
                        style={{
                          textAlign: "center",
                          position: "absolute",
                          right: "2%",
                          top: "50%",
                        }}
                      >
                        <Icon
                          className=""
                          iconName="ArrowForwardIcon"
                          onClick={handleBoxClick}
                        />
                      </div>
                    }
                  ></TooltipComponent>
                </div>
              </>
            ) : (
              <div className="container-center">
                <CircularProgress />
              </div>
            )}*/}
            <>
              <h2 className="">Välkommen till vår trafikskola!</h2>
              <p
                style={{
                  paddingTop: "10px",
                  margin: 0,
                  marginBottom: "10px",
                  fontSize: "0.9rem",
                }}
              >
                På vår trafikskola prioriterar vi säkerhet. Våra erfarna
                instruktörer finns här för att vägleda dig genom din
                inlärningsresa och hjälpa dig att bli en självsäker och
                ansvarsfull förare.
              </p>

              <p
                style={{
                  paddingTop: "10px",
                  margin: 0,
                  fontSize: "0.9rem",
                }}
              >
                Vi erbjuder vi ett utbud av kurser som är anpassade efter dina
                behov. Kontakta oss idag för att komma igång!
              </p>

              <div>
                <TooltipComponent
                  title={
                    <h5 className="home__tooltip__content__box">Priser</h5>
                  }
                  placement="top-end"
                  wrappedContent={
                    <div
                      className="home__box__icon"
                      style={{
                        textAlign: "center",
                        position: "absolute",
                        right: "2%",
                        top: "50%",
                      }}
                    >
                      <Icon
                        className=""
                        iconName="ArrowForwardIcon"
                        onClick={handleBoxClick}
                      />
                    </div>
                  }
                ></TooltipComponent>
              </div>
            </>
          </Box>
        )}
      </div>
      <div className="divider"></div>

      {/*testData && (
        <ul
          style={{
            display: "flex",
            border: "1px solid black",
            padding: "20px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {testData.map((row, rowIndex) => (
            <li key={rowIndex}>
              <ul>
                {row.map((item, itemIndex) => (
                  <li
                    style={{ border: "1px solid black", padding: "20px" }}
                    key={itemIndex}
                  >
                    {item ? item : ""}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      )*/}
      <div className="home-content">
        <div
          className="flex-container"
          style={{
            marginLeft: "10%",
            marginRight: "10%",
          }}
        >
          <div className="about_header_icon">
            <Label />
          </div>

          <h1 className="company-title">Välkommen!</h1>
        </div>
        <div
          className="home__wrapper"
          style={{
            height: "70vh",
            justifyContent: "center",
            marginLeft: "10%",
            marginRight: "10%",
            alignContent: "center",
          }}
        >
          <div className="home-text">
            <div>
              <h6
                className="home-text__col__text"
                style={{
                  border: "2px solid gainsboro",
                  padding: "20px",

                  boxShadow: "1px 1px 1px black",
                }}
                ref={(el) => (boxesRef.current[0] = el)}
              >
                Vi vill informera att i nuläget har vi gott om körlektioner med
                personbil mellan kl 8.30- 16.50.
              </h6>
            </div>

            <div>
              <h6
                className="home-text__col__text"
                style={{
                  border: "2px solid gainsboro",
                  padding: "20px",

                  boxShadow: "1px 1px 1px black",
                }}
                ref={(el) => (boxesRef.current[1] = el)}
              >
                Vi har också lediga tider för handledarkurs och riskettan.
              </h6>
            </div>

            <div>
              <h6
                className="home-text__col__text"
                style={{
                  border: "2px solid gainsboro",
                  padding: "20px",

                  boxShadow: "1px 1px 1px black",
                }}
                ref={(el) => (boxesRef.current[3] = el)}
              >
                Ring gärna oss på 0700-907210 eller maila dagar och tider
                önskemål till{" "}
                <a
                  href="mailto:korkarlens.trafikskola@hotmail.com"
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "inherit",
                  }}
                >
                  korkarlens.trafikskola@hotmail.com
                </a>
                {/*<TextTruncate
              line={1}
              element="span"
              truncateText=""
              text="korkarlens.trafikskola@hotmail.com"
              textTruncateChild={
               
              }
              containerClassName="email-link"
            />*/}
              </h6>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginTop: "10%",
            marginBottom: "10%",
            padding: "40px",
          }}
        >
          <p className="center-container">
            Maila eller ring oss för frågor om exempelvis pris Mvh personalen
          </p>
        </div>

        <div className="divider"></div>
        <Offers
          offerTitle={{
            swedish: "Jul/Nyårs Erbjudande!",
            english: "Christmas/New Year's Offer!",
          }}
          offersList={offers}
        />
        <div className="divider"></div>

        <div className="divider"></div>
        <div
          className="about__home"
          style={{ marginLeft: "10%", marginRight: "10%" }}
        >
          <h3 className="about__home__title">
            Vi erbjuder på Körkarlens trafikskola
          </h3>
          <ul className="about-ul">
            <li className="center-li">
              ✓ Alltid mycket låga och konkurrenskraftiga priser
            </li>
            <li className="center-li">✓ Hög kvalitet i undervisningen</li>
            <li className="center-li">
              ✓ En trevlig och avspänd atmosfär på trafikskolan, vilket skapar
              en bra miljö för en god inlärning
            </li>
            <li className="center-li">
              ✓ Vi erbjuder även utbildning för Yrkesförarkompetens (YKB)
            </li>
            <li className="center-li">
              ✓ Vi har lärare som pratar engelska och arabiska
            </li>
          </ul>
        </div>
        <div className="divider"></div>

        <div
          className="tabbed__section"
          style={{ marginLeft: "10%", marginRight: "10%" }}
        >
          <h2 className="tabbed__section__title">Våra utbildningar</h2>
          <p
            style={{
              marginTop: "16px",
              marginLeft: "15px",
              fontStyle: "oblique",
            }}
          >
            Övrig information
          </p>
          <Tabbed />
        </div>
      </div>
    </div>
  );
};

export default Home;
