// PriceContext.js
import React, { createContext, useState, useEffect } from "react";
import { PriceCategory, PriceItem } from "../models/price";

// Create a context
export const PriceContext = createContext();

// Define the price categories and items
export const prices = [
  new PriceCategory({
    name: "Personbil B",
    columns: [
      { label: "Lektioner och körpaket" },
      { label: "Pris" },
      { label: "Antal Lektioner" }, // Add a column for lesson count

      { label: "-" },
      { label: "-" }, // Add a column for books
      { label: "-" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "40 minuter körlektion",
        price: 590,
        lessonCount: 1,
      }),
      new PriceItem({
        type: "Körpaket 5 lektioner",
        price: 2925,
        lessonCount: 5,
      }),
      new PriceItem({
        type: "Körpaket 10 lektioner",
        price: 5850,
        lessonCount: 10,
      }),
      new PriceItem({
        type: "Intensivpaket 20 lektioner",
        price: 11400,
        lessonCount: 20,
      }),
      new PriceItem({
        type: "Riskettan",
        price: 600,
      }),
      new PriceItem({
        type: "Risktvåan",
        price: 2000,
      }),
      // Continue adding other PriceItem objects following the same pattern...
    ],
    icon: "DirectionsCarIcon",
  }),
  new PriceCategory({
    name: "Personbil B släp",
    columns: [
      { label: "Lektioner och körpaket" },
      { label: "Pris" },
      { label: "Antal Lektioner" }, // Add a column for lesson count

      { label: "Lån av fordon" },
      { label: "-" }, // Add a column for books
      { label: "-" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "B96 Tre lektioner",
        price: 4000,
        lessonCount: 3,
        hasVehicleLoan: true,
      }),
      new PriceItem({
        type: "BE Tre lektioner",
        price: 4000,
        lessonCount: 3,
        hasVehicleLoan: true,
      }),
    ],
    icon: "DirectionsCarIcon",
  }),
  new PriceCategory({
    name: "Tung lastbil C/C1",
    columns: [
      { label: "Lektioner och körpaket" },
      { label: "Pris" },
      { label: "Antal Lektioner" }, // Add a column for lesson count

      { label: "-" },
      { label: "Böcker" }, // Add a column for books
      { label: "Online test" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "40 minuter körlektion",
        price: 880,
        lessonCount: 1,
      }),
      new PriceItem({
        type: "Körpaket 5 lektioner",
        price: 4300,
        lessonCount: 5,
      }),
      new PriceItem({
        type: "Undervisning funk 10 lektioner",
        price: 7000,
        lessonCount: 10,
        hasFunc: true,
      }),
      new PriceItem({
        type: "Körpaket 10 lektioner",
        price: 8500,
        lessonCount: 10,
      }),
      new PriceItem({
        type: "Intensivpaket 15 lektioner",
        price: 15000,
        hasBooks: true,
        hasOnlineTest: true,
      }),
    ],
    icon: "LocalShippingIcon",
  }),
  new PriceCategory({
    name: "Tung lastbil CE",
    columns: [
      { label: "Lektioner och körpaket" },
      { label: "Pris" },
      { label: "Antal Lektioner" }, // Add a column for lesson count

      { label: "-" },
      { label: "Böcker" }, // Add a column for books
      { label: "Online test" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "40 minuter körlektion",
        lessonCount: 1,
        price: 980,
      }),
      new PriceItem({
        type: "Körpaket 5 lektioner",
        price: 4800,
        lessonCount: 5,
      }),
      new PriceItem({
        type: "Undervisning funk 10 lektioner",
        price: 7000,
        lessonCount: 10,
      }),
      new PriceItem({
        type: "Körpaket 10 lektioner",
        price: 9500,
        lessonCount: 10,
      }),
      new PriceItem({
        type: "Intensivpaket 15 lektioner",
        price: 16500,
        lessonCount: 15,
        hasBooks: true,
        hasOnlineTest: true,
      }),
    ],
    icon: "LocalShippingIcon",
  }),
  new PriceCategory({
    name: "Buss D",
    columns: [
      { label: "Lektioner och körpaket" },
      { label: "Pris" },
      { label: "Antal Lektioner" }, // Add a column for lesson count
      { label: "-" },
      { label: "Böcker" }, // Add a column for books
      { label: "Online test" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "40 minuter körlektion",
        price: 880,
        lessonCount: 1,
      }),
      new PriceItem({
        type: "Körpaket 5 lektioner",
        price: 4300,
        lessonCount: 5,
      }),
      new PriceItem({
        type: "Undervisning funk 10 lektioner",
        price: 7000,
        lessonCount: 10,
      }),
      new PriceItem({
        type: "Körpaket 10 lektioner",
        price: 8500,
        lessonCount: 10,
      }),
      new PriceItem({
        type: "Intensivpaket 15 lektioner",
        price: 15000,
        lessonCount: 10,
        hasBooks: true,
        hasOnlineTest: true,
      }),
    ],
    icon: "DirectionsBusIcon",
  }),
  new PriceCategory({
    name: "Yrkeskompetensbevis",
    columns: [
      { label: "Buss och Lastbil" },
      { label: "Pris" },
      { label: "-" }, // Add a column for lesson count
      { label: "Buss" }, //hasLoanOfVehicle, ugly fix
      { label: "Lastbil" }, //hasBooks, this really needs to be fixed
      { label: "-" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "140 tim",
        price: 35000,
        hasVehicleLoan: true,
        hasBooks: true,
      }),
      new PriceItem({
        type: "280 tim",
        price: 60000,
        hasVehicleLoan: true,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Fortbildning 35 tim",
        price: 12000,
        hasVehicleLoan: true,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Komplettering 35 tim, endast lastbil",
        price: 12000,
        hasBooks: true,
      }),
    ],
    icon: "WorkspacePremiumIcon",
  }),
  new PriceCategory({
    name: "Övrigt",
    columns: [
      { label: "Kurser och teori" },
      { label: "Pris" },
      { label: "-" }, // Add a column for lesson count
      { label: "-" },
      { label: "-" }, // Add a column for books
      { label: "-" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "Teori hemifrån(obeg)",
        price: 600,
        hasOnlineTest: true,
      }),
      new PriceItem({
        type: "Teoriprov",
        price: 325,
      }),
      new PriceItem({
        type: "Körprov",
        price: 800,
      }),
      new PriceItem({
        type: "Handledarkurs",
        price: 300,
      }),
      new PriceItem({
        type: "Inskrivning i datorsystemet Personbil B",
        price: 200,
      }),
      new PriceItem({
        type: "Inskrivning i datorsystemet Lastbil",
        price: 500,
      }),
    ],
    icon: "ListAltIcon", // Replace with appropriate icon
  }),
  new PriceCategory({
    name: "Böcker",
    columns: [
      { label: "Lektioner och körpaket" },
      { label: "Pris" },
      { label: "-" }, // Add a column for lesson count
      { label: "-" },
      { label: "Böcker" }, // Add a column for books
      { label: "Online test" }, // Add a column for online test
    ],
    items: [
      new PriceItem({
        type: "Köra lastbil",
        price: 700,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Köra buss",
        price: 700,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Trafikens grunder",
        price: 700,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Yrkesförarkompetens",
        price: 700,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Last säkerhetsboken (TYA)",
        price: 100,
        hasBooks: true,
      }),
      new PriceItem({
        type: "Onlinetest",
        price: 700,
        hasOnlineTest: true,
      }),
    ],
    icon: "LibraryBooksIcon", // Replace with appropriate icon
  }),
  new PriceCategory({
    name: "Lån av fordon",
    columns: [
      { label: "Typ" },
      { label: "Pris" },
      { label: "-" },
      { label: "Lån av fordon" },
      { label: "-" },
      { label: "Online test" }, // Add a column for online test
      { label: "Funk" },
    ],
    items: [
      new PriceItem({
        type: "Personbil B",
        price: 1200,
        hasVehicleLoan: true,
      }),
      new PriceItem({
        type: "BE/B96",
        price: 1500,
        hasVehicleLoan: true,
      }),
      new PriceItem({
        type: "Tung lastbil C uppkörning",
        price: 3000,
        hasVehicleLoan: true,
      }),
      new PriceItem({
        type: "Tung lastbil C funk",
        price: 2000,
        hasVehicleLoan: true,
        hasFunc: true,
      }),
      new PriceItem({
        type: "Tung lastbil CE uppkörning",
        price: 3500,
        hasVehicleLoan: true,
      }),
      new PriceItem({
        type: "Tung lastbil CE funk",
        price: 2500,
        hasVehicleLoan: true,
        hasFunc: true,
      }),
      new PriceItem({
        type: "Buss D uppkörning",
        price: 3000,
        hasVehicleLoan: true,
      }),
      new PriceItem({
        type: "Buss D funk",
        price: 2000,
        hasVehicleLoan: true,
        hasFunc: true,
      }),
    ],
    icon: "CarRentalIcon", // Replace with appropriate icon
  }),
  new PriceCategory({
    name: "Guld, Silver, Brons-paketen",
    columns: [
      { label: "Lektioner och körpaket, Gäller C,D och CE" },
      { label: "Pris" },
      { label: "Antal lektioner" }, // Add a column for lesson count
      { label: "Lån av lastbil" }, // Add a column for lesson count
      { label: "-" },
      { label: "-" },
      { label: "Funk" }, // Add a column for Func
    ],
    items: [
      new PriceItem({
        type: "BRONS C och D",
        price: 16000,
        lessonCount: "10 st",
        hasVehicleLoan: true,
        hasBooks: false,
        hasOnlineTest: false,

        hasFunc: true,
      }),
      new PriceItem({
        type: "SILVER C och D",
        price: 24000,
        lessonCount: "20 st",
        hasVehicleLoan: true,
        hasBooks: false,
        hasOnlineTest: false,
        hasFunc: true,
      }),
      new PriceItem({
        type: "GULD C och D",
        price: 32000,
        lessonCount: "30 st",
        hasVehicleLoan: true,
        hasBooks: false,
        hasOnlineTest: false,
        hasFunc: true,
      }),
      new PriceItem({
        type: "BRONS CE",
        price: 17000,
        lessonCount: "10 st",
        hasVehicleLoan: true,
        hasBooks: false,
        hasOnlineTest: false,
        hasFunc: true,
      }),
      new PriceItem({
        type: "SILVER CE",
        price: 26000,
        lessonCount: "20 st",
        hasVehicleLoan: true,
        hasBooks: false,
        hasOnlineTest: false,
        hasFunc: true,
      }),
      new PriceItem({
        type: "GULD CE",
        price: 35000,
        lessonCount: "30 st",
        hasVehicleLoan: true,
        hasBooks: false,
        hasOnlineTest: false,
        hasFunc: true,
      }),
    ],
    icon: "LocalOfferIcon", // Replace with appropriate icon
  }),
  // Add other categories and their data here...
];

// Create a provider for components to consume and update the price data
export const PriceProvider = ({ children }) => {
  // Initialize the price state

  return (
    <PriceContext.Provider value={{ prices }}>{children}</PriceContext.Provider>
  );
};
