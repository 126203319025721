// price_item.js
export default class PriceItem {
  static idCounter = 0;
  constructor({
    type = "",
    price = 0,
    lessonCount = 0,
    hasVehicleLoan = false,
    hasBooks = false,
    hasOnlineTest = false,
    hasFunc = false,
    columnId = 0, // New property to specify the column ID
  }) {
    this.id = PriceItem.getNextId();
    this.type = type;
    this.price = price;
    this.lessonCount = lessonCount;
    this.hasVehicleLoan = hasVehicleLoan;
    this.hasBooks = hasBooks;
    this.hasOnlineTest = hasOnlineTest;
    this.hasFunc = hasFunc;
    this.columnId = columnId; // Assign column ID
  }
  static getNextId() {
    return ++PriceItem.idCounter;
  }
  static create({
    type,
    price,
    lessonCount,
    hasVehicleLoan,
    hasBooks,
    hasOnlineTest,
    hasFunc,
    columnId, // Include columnId in creation
  }) {
    return new PriceItem({
      type,
      price,
      lessonCount,
      hasVehicleLoan,
      hasBooks,
      hasOnlineTest,
      hasFunc,
      columnId, // Pass columnId to constructor
    });
  }
}
