import React from "react";
import TextField from "@mui/material/TextField";
import Icon from "../icon/icon";

const SearchBar = ({ value, onChange }) => {
  return (
    <div className="search-container">
      <Icon iconName="SearchIcon" className="search-icon" />
      <TextField
        label="Sök"
        id="fullWidth"
        className="search-input"
        type="text"
        //placeholder="Sök efter vald utb"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default SearchBar;
