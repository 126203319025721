import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "../icon/icon";

const DynamicTable = ({ data, searchQuery }) => {
  const { name, columns, items, icon } = data;

  if (!columns || !items || !icon) return null;

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="dynamic table" style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell
                className="table__cell__width"
                colSpan={columns.length}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Icon iconName={icon} />
                  <h2 style={{ marginLeft: "10px" }}>{name}</h2>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column, columnIndex) => (
                <TableCell key={columnIndex}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, rowIndex) => (
              <TableRow
                key={rowIndex}
                //colSpan={rowIndex === 0 ? 1 : undefined}
                hover
              >
                {/* Render each property of the item in the corresponding column */}
                {columns.map((column, columnIndex) => (
                  <TableCell
                    align="left"
                    className="table__cell__width"
                    key={columnIndex}
                  >
                    {/* Access item properties using columnId */}
                    {getColumnValue(item, column.id)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const getColumnValue = (item, columnId) => {
  switch (columnId) {
    case 1:
      return item.type;
    case 2:
      return item.price;
    case 3:
      return item.lessonCount === 0 ? "-" : item.lessonCount;
    case 4:
      return item.hasVehicleLoan ? <div>&#10003;</div> : "-";
    case 5:
      return item.hasBooks ? <div>&#10003;</div> : "-";
    case 6:
      return item.hasOnlineTest ? <div>&#10003;</div> : "-";
    case 7:
      return item.hasFunc ? <div>&#10003;</div> : "-";
    default:
      // Return the corresponding item property if columnId is within the range

      return "";
  }
};

export default DynamicTable;
