// price_category.js
import PriceItem from "./price_item";

export default class PriceCategory {
  static idCounter = 0;
  constructor({ name, columns, items, icon }) {
    this.id = PriceCategory.getNextId();
    this.name = name;
    this.columns = columns.map((column, index) => ({
      id: index + 1, // Assigning a unique ID to each column
      label: column.label,
    }));
    this.items = items.map((item, index) =>
      PriceItem.create({
        type: item.type,
        price: item.price,
        lessonCount: item.lessonCount,
        hasVehicleLoan: item.hasVehicleLoan,
        hasBooks: item.hasBooks,
        hasOnlineTest: item.hasOnlineTest,
        hasFunc: item.hasFunc,
        columnId: index + 1, // Assigning columnId directly
      })
    );
    this.icon = icon;
  }
  static getNextId() {
    return ++PriceCategory.idCounter;
  }
}
