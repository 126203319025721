import volkswagen from "./car-images/volkswagen.jpg";
import car_image_white from "./car-images/Vit automat 2.jpg";
import truck_image_white from "./car-images/IMG_FORDONLASTBIL.jpg";
import vw_car from "./car-images/vw_car.jpg";
import interior_vw from "./car-images/car_interior_vw.jpg";
import vw_car_black from "./car-images/vw_car_black.jpg";
import car_image_one from "./car-images/IMG_FORDON1.jpg";
import car_image_two from "./car-images/IMG_FORDON2.JPG";
import car_image_three from "./car-images/IMG_6871.JPG";
import car_image_four from "./car-images/IMG_6881.JPG";
import car_image_five from "./car-images/vw_car_grey.jpg";

import truck_image_one from "./truck_images/white-truck.jpg";

import buss_image_one from "./buss_images/buss_image.jpg";
// images.js
const images = {
  // Add other image paths here...
  volkswagen,
  interior_vw,
  car_image_white,
  truck_image_white,
  vw_car,
  vw_car_black,
  car_image_one,
  car_image_two,
  car_image_three,
  car_image_four,
  car_image_five,

  truck_image_one,

  buss_image_one,
};

export default images;
