import React from "react";
import Tooltip from "@mui/material/Tooltip";

const TooltipComponent = ({ title, wrappedContent, placement }) => {
  return (
    <div>
      <Tooltip title={title} placement={placement} arrow>
        {wrappedContent}
      </Tooltip>
    </div>
  );
};

export default TooltipComponent;
