import React from "react";
import {
  ArrowForward,
  Article,
  CarRental,
  CreditCard,
  DirectionsBus,
  DirectionsCar,
  Home,
  Info,
  Label,
  LibraryBooks,
  ListAlt,
  LocalOffer,
  LocalShipping,
  Payments,
  Search,
  WorkspacePremium,
} from "@mui/icons-material";

/**
 * DEFAULT ICON IS LABEL
 * @param {string} iconName
 * @param {string} className
 * @param {function} onClick
 * @returns {JSX.Element}
 */
const Icon = ({ iconName, className, onClick }) => {
  let iconComponent;

  switch (iconName) {
    case "ArrowForwardIcon":
      iconComponent = <ArrowForward className={className} onClick={onClick} />;
      break;
    case "ArticleIcon":
      iconComponent = <Article className={className} onClick={onClick} />;
      break;
    case "CarRentalIcon":
      iconComponent = <CarRental className={className} onClick={onClick} />;
      break;
    case "CreditCardIcon":
      iconComponent = <CreditCard className={className} onClick={onClick} />;
      break;
    case "DirectionsBusIcon":
      iconComponent = <DirectionsBus className={className} onClick={onClick} />;
      break;
    case "DirectionsCarIcon":
      iconComponent = <DirectionsCar className={className} onClick={onClick} />;
      break;
    case "HomeIcon":
      iconComponent = <Home className={className} onClick={onClick} />;
      break;
    case "InfoIcon":
      iconComponent = <Info className={className} onClick={onClick} />;
      break;
    case "LabelIcon":
      iconComponent = <Label className={className} onClick={onClick} />;
      break;
    case "LibraryBooksIcon":
      iconComponent = <LibraryBooks className={className} onClick={onClick} />;
      break;
    case "ListAltIcon":
      iconComponent = <ListAlt className={className} onClick={onClick} />;
      break;
    case "LocalOfferIcon":
      iconComponent = <LocalOffer className={className} onClick={onClick} />;
      break;
    case "LocalShippingIcon":
      iconComponent = <LocalShipping className={className} onClick={onClick} />;
      break;
    case "PaymentsIcon":
      iconComponent = <Payments className={className} onClick={onClick} />;
      break;
    case "SearchIcon":
      iconComponent = <Search className={className} onClick={onClick} />;
      break;
    case "WorkspacePremiumIcon":
      iconComponent = (
        <WorkspacePremium className={className} onClick={onClick} />
      );
      break;
    default:
      iconComponent = <Label className={className} onClick={onClick} />;
      break;
  }

  return iconComponent;
};

export default Icon;
