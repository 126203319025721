import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import images from "../images/index";

export const Image_list = (props) => {
  function srcset(image, width, height, rows = 1, cols = 1) {
    return {
      src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${width * cols}&h=${
        height * rows
      }&fit=crop&auto=format&dpr=2 2x`,
    };
  }
  const itemData = [
    {
      img: images.volkswagen,
      title: "Volkswagen",
      author: "@KorkarlensTrafikskola",
      featured: true,
    },
    {
      img: images.interior_vw,
      title: "Volkswagen",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.car_image_white,
      title: "Volkswagen",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.truck_image_white,
      title: "Lastbil",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.vw_car,
      title: "Volkswagen",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.vw_car_black,
      title: "Volkswagen",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.car_image_one,
      title: "Volkswagen Golf",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.car_image_two,
      title: "Volkswage Golfn",
      author: "@KorkarlensTrafikskola",
      featured: true,
    },
    {
      img: images.car_image_three,
      title: "Volkswagen Golf",
      author: "@KorkarlensTrafikskola",
    },
    {
      img: images.car_image_four,
      title: "Volkswagen",
      author: "@KorkarlensTrafikskola",
    },
  ];
  return (
    <div>
      <ImageList
        sx={{
          width: "100%",
          height: "100%",
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: "translateZ(0)",
          paddingRight: "10px",
        }}
        rowHeight={200}
        gap={1}
        variant="string"
        cols={2}
      >
        {itemData.map((item) => {
          const cols = item.featured ? 2 : 1;
          const rows = item.featured ? 2 : 1;

          return (
            <ImageListItem key={item.img} cols={cols} rows={rows}>
              <img
                {...srcset(item.img, 200, 150, rows, cols)}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                sx={{
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                    "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                  display: "flex",
                  alignItems: "normal",
                }}
                title={item.title}
                position="top"
                actionPosition="left"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </div>
  );
};

export default Image_list;
