import React, { useContext, useState } from "react";
import { PriceContext } from "./context/price_context";
import DynamicTable from "./components/table/table";
import Icon from "./components/icon/icon";
import SearchBar from "./components/search/search";
const Price = () => {
  const { prices } = useContext(PriceContext);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterItems = (data, query) => {
    if (!query) return data;

    return data.filter((category) => {
      // Check if the category name matches the query
      if (category.name.toLowerCase().includes(query.toLowerCase())) {
        return true;
      }

      // If not, check if any of the item values match the query
      return category.items.some((item) => {
        return Object.values(item).some((value) =>
          String(value).toLowerCase().includes(query.toLowerCase())
        );
      });
    });
  };

  const filteredPrices = filterItems(prices, searchQuery);

  const _renderPriceTable = () => {
    if (!prices || !Array.isArray(prices)) return null;
    return filteredPrices.map((category, index) => (
      <div key={index} className="price-category">
        <DynamicTable key={index} data={category} />
      </div>
    ));
  };

  return (
    <div
      className="table-responsive"
      style={{
        marginLeft: "10%",
        marginRight: "10%",
      }}
    >
      {/* Render price tables for each category */}
      <div className="flex-container">
        <div className="about_header_icon">
          <Icon />
        </div>

        <h1 className="company-title">Priser</h1>
      </div>
      <SearchBar value={searchQuery} onChange={handleSearchInputChange} />

      {_renderPriceTable()}
    </div>
  );
};

export default Price;
