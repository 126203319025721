import React, { useState } from "react";
import { Tabs, Tab, Typography } from "@mui/material";
import images from "./images/index";
import { NavLink } from "react-router-dom";

export const Tabbed = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="">
      <div>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Personbil B" />
          <Tab label="Personbil BE" />
          <Tab label="Lastbil C/C1" />
          <Tab label="Buss D" />
          <Tab label="Trafikveket" />
          <Tab label="Transportstyrelsen" />
        </Tabs>
      </div>

      <div className="tabs">
        {activeTab === 0 && (
          <div className="tab">
            <div className="tab__info">
              <Typography variant="h5">Personbil B</Typography>
              <Typography>
                Med körkortsbehörigheten B får du köra personbilar och lätta
                lastbilar med en totalvikt på högst 3 500 kg. Släpets totalvikt
                får inte vara mer än 750 kg.
              </Typography>
            </div>
            <img
              alt="truck"
              src={images.car_image_five}
              className="tab__image"
            />
          </div>
        )}
        {activeTab === 1 && (
          <div className="tab">
            <div className="tab__info">
              <Typography variant="h5">Personbil BE</Typography>
              <Typography>
                Med behörigheten BE får du köra personbilar eller lätta
                lastbilar med en totalvikt på högst 3 500 kg och en eller flera
                släp kopplade till bilen. Släpets eller släpens sammanlagda
                totalvikt får dock inte vara över 3 500 kg.
              </Typography>
            </div>

            <img
              alt="truck"
              src={images.truck_image_one}
              className="tab__image"
            />
          </div>
        )}
        {activeTab === 2 && (
          <div className="tab">
            <div className="tab__info">
              <Typography variant="h5">Lastbil C/C1</Typography>
              <Typography>
                Körkortsbehörigheten C ger rätt att köra lastbilar utan övre
                viktbegränsning. Du får också köra personbilar med en totalvikt
                över 3500 kg utan övre viktbegränsning. Du får koppla till en
                lätt släpvagn med totalvikt på högst 750 kg.
                Körkortsbehörigheten C1 ger rätt att köra lastbilar med en
                totalvikt på högst 7500 kg. Med ett C1-körkort får du också köra
                personbilar med en totalvikt över 3500 kg men inte över 7500 kg.
              </Typography>
            </div>
            <img
              alt="truck"
              src={images.truck_image_one}
              className="tab__image"
            />
          </div>
        )}
        {activeTab === 3 && (
          <div className="tab">
            <div className="tab__info">
              <Typography variant="h5">Buss D</Typography>
              <Typography>
                Körkortsbehörigheten D ger rätt att köra bussar oavsett antal
                sittplatser eller längd. Du får också dra en lätt släpvagn med
                totalvikt på högst 750 kg.
              </Typography>
            </div>
            <img
              className="tab__image"
              alt="truck"
              src={images.buss_image_one}
            />
          </div>
        )}
        {activeTab === 4 && (
          <div className="tab">
            <div className="tab__info">
              <Typography variant="h5">Mer information</Typography>
              <Typography>
                För mer information om körkort hos Trafikverket, besök deras
                webbplats:
              </Typography>
              <NavLink
                to="https://www.trafikverket.se/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Trafikverket - Körkort
              </NavLink>
            </div>
          </div>
        )}
        {activeTab === 5 && (
          <div className="tab">
            <div className="tab__info">
              <Typography variant="h5">Mer information</Typography>
              <Typography>
                För mer information om körkort hos Transportstyrelsen, besök
                deras webbplats:
              </Typography>
              <NavLink
                to="https://www.transportstyrelsen.se/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Transportstyrelsen
              </NavLink>
              <Typography>
                För mer information om körkorttillstånd hos Transportstyrelsen,
                besök deras webbplats:
              </Typography>
              <NavLink
                to="https://www.transportstyrelsen.se/sv/Blanketter/Vag/korkort/privatperson/ansok-om-korkortstillstand-grupp-i/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Transportstyrelsen - Körkorttillstånd
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
